import React, { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';

import { injectedComponent, Text } from '@base/core';
import { EMAIL_REGEXP } from '../../../../shared/configs/constants';

export default injectedComponent(
  {
    name: 'AuthDialogBase',
    services: ['api'],
    styles: ['dialog', 'targetDialog', 'button', 'self'],
  },
  function AuthDialogBase({
    onSNLogin,
    login,
    onLoginChange,
    password,
    onPasswordChange,
    updating,
    authError,
    validationId,
    validity,
    validate,
    title,
    beforeActionButton,
    actionButtonText,
    onAction,
    afterActionButton,
    services: { api },
    styles: {
      dialog: dialogStyles,
      targetDialog: targetDialogStyles,
      button: buttonStyles,
      self: selfStyles,
    },
  }) {
    const [isPasswordShown, setPasswordShown] = useState(false);

    const loginRef = useRef();
    const passwordRef = useRef();

    const validateOwn = () =>
      loginRef.current.checkValidity() && passwordRef.current.checkValidity();

    const handleLoginChange = (e) => {
      validate(validationId, validateOwn());
      onLoginChange(e.target.value);
    };

    const handlePasswordChange = (e) => {
      validate(validationId, validateOwn());
      onPasswordChange(e.target.value);
    };

    const handleEmailKeyPress = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        e.stopPropagation();
        if (!password) {
          passwordRef.current.focus();
        } else if (login && password) {
          onAction();
        }
      }
    };

    const handlePasswordKeyPress = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        e.stopPropagation();
        if (login && password) {
          onAction();
        }
      }
    };

    const handlePasswordShow = (e) => {
      e.stopPropagation();
      e.preventDefault();
      setPasswordShown(!isPasswordShown);
    };

    const handleGoogleLogin = () => {
      typeof onSNLogin === 'function'
        ? onSNLogin('google', api.baseUrl)
        : document.location.assign(api.baseUrl + '/auth/google');
    };

    const handleAppleLogin = () => {
      typeof onSNLogin === 'function'
        ? onSNLogin('apple', api.baseUrl)
        : document.location.assign(api.baseUrl + '/auth/apple');
    };

    const handleFacebookLogin = () => {
      typeof onSNLogin === 'function'
        ? onSNLogin('facebook', api.baseUrl)
        : document.location.assign(api.baseUrl + '/auth/facebook');
    };

    useEffect(() => {
      validate(validationId, validateOwn());
    }, []);

    return (
      <div className={clsx(targetDialogStyles.dialog, selfStyles.authDialog)}>
        <h3 className={dialogStyles.dialogTitle}>{title}</h3>
        <div className={selfStyles.authButtonGroup}>
          <button
            type="button"
            className={clsx(selfStyles.authButton, selfStyles.authButton__google)}
            onClick={handleGoogleLogin}
          >
            <Text>dialog.login.button__google</Text>
          </button>
          <button
            type="button"
            className={clsx(selfStyles.authButton, selfStyles.authButton__apple)}
            onClick={handleAppleLogin}
          >
            <Text>dialog.login.button__apple</Text>
          </button>
          <button
            type="button"
            className={clsx(selfStyles.authButton, selfStyles.authButton__facebook)}
            onClick={handleFacebookLogin}
          >
            <Text>dialog.login.button__facebook</Text>
          </button>
        </div>
        <p className={selfStyles.dividerBox}>
          <span className={selfStyles.dividerText}>
            <Text>dialog.login.divider__or_via_email</Text>
          </span>
          <span className={selfStyles.divider}></span>
        </p>
        <form className={clsx(dialogStyles.form, selfStyles.authForm)}>
          <input
            ref={loginRef}
            type="email"
            className={dialogStyles.formInput}
            placeholder="Your email"
            pattern={EMAIL_REGEXP}
            value={login}
            onChange={handleLoginChange}
            onKeyPress={handleEmailKeyPress}
          />
          <span className={selfStyles.passwordWrapper} data-validated="false">
            <input
              ref={passwordRef}
              type={isPasswordShown ? 'text' : 'password'}
              className={dialogStyles.formInput}
              placeholder="Your password"
              pattern=".{6,}"
              value={password}
              onChange={handlePasswordChange}
              onKeyPress={handlePasswordKeyPress}
            />
            <button
              className={clsx(selfStyles.passwordVisibilityBtn, {
                [selfStyles.showPassword]: !isPasswordShown,
              })}
              onClick={handlePasswordShow}
            ></button>
          </span>
          {authError && (
            <span className={clsx(dialogStyles.errorMessage, selfStyles.errorMessage)}>
              {authError}
            </span>
          )}
          {beforeActionButton}
          <button
            type="button"
            className={clsx(buttonStyles.primaryBtn, buttonStyles.primaryBtn__medium, {
              [buttonStyles.primaryBtn__disabled]: updating || !validity,
            })}
            onClick={onAction}
          >
            {actionButtonText}
          </button>
          {afterActionButton}
        </form>
      </div>
    );
  }
);
