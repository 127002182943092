export default {
  13: [
    {
      image: 'au/wildfire.jpg',
      title: 'Australia Wildfires Relief Fund',
      goal: '$5,000,000',
      raised: '$3,562,460',
      donations: '28,018',
      link: 'https://www.globalgiving.org/projects/australian-wildfire-relief-fund/',
      summary:
        'High winds and drought conditions are causing wildfires to spread rapidly through Australia, prompting mass evacuations, destroying homes and killing millions of animals. This fund will support immediate rescue and relief efforts for people and animals impacted by the fires and will eventually transition to funding long-term assistance to help residents recover and rebuild. All donations to this fund will exclusively support wildfire recovery.',
    },
    {
      image: 'au/wildlife.jpg',
      title: 'Aussie Wildlife Recovery & Emergency Preparedness',
      goal: '$2,000,000',
      raised: '$1,475,267',
      donations: '1,540',
      link: 'https://www.globalgiving.org/projects/emergency-rescue-and-care-of-australian-animals/',
      summary:
        'This project will assist in the ongoing recovery of habitat and support for wildlife after emergency weather events. Bushfires and flooding destroy habitat and food sources. In the 2020 bushfires alone an estimated 3 billion native animals were lost. Additionally this project will assist WIRES in developing systems and capacity to respond to future large scale events such as the 2022 flood disaster.',
    },
    {
      image: 'au/red-cross.jpg',
      title: 'Australian Red Cross',
      goal: '$1,000,000',
      raised: '$1,296',
      donations: '3',
      link: 'https://www.globalgiving.org/projects/australian-red-cross/',
      summary: '',
    },
    {
      image: 'au/fire-management.jpg',
      title: 'Fire Management across Australia',
      goal: '$1,000,000',
      raised: '$1,441',
      donations: '14',
      link: 'https://www.globalgiving.org/projects/fire-management-across-australia/',
      summary:
        "Implementing Australia's largest non-Government fire program. The primary objective of AWC's Northern fire program is to measurably reduce the frequency and extent of late season wildfires on a vast, landscape-scale. This objective is achieved through the delivery of prescribed burning operations across 7.5 million hectares covering multiple tenures, including AWC land, indigenous land and commercial pastoral land. Eco-fire is a template for best-practice fire management in northern Australia.",
    },
    {
      image: 'all/ukraine.jpg',
      title: 'Ukraine Crisis Relief Fund',
      goal: '$57,000,000',
      raised: '$53,560,753',
      donations: '179,325',
      link: 'https://www.globalgiving.org/projects/ukraine-crisis-relief-fund/',
      summary:
        'It is hard to believe that the war in Ukraine has raged on for six months. GlobalGiving created the Ukraine Crisis Relief Fund days before Russia officially launched its invasion in February. At the time, our partners in Ukraine were already telling us that their communities were anxious and preparing for difficult days ahead.\
        As we continue to listen to our partners, they are sharing the worries they have for themselves and their families as the war drags on. They are worries like: “Funding has gone down—how can we keep supporting our people and our animals?” “The school year is starting, but where can we send our children safely and comfortably?” “The winter is coming, and we don’t know where we can stay.”\
        In six months, the war has resulted in several troubling records, including the largest and fastest displacement crisis in Europe since World War II, and the largest global fuel and energy crisis in decades.\
        But we have also seen record levels of generosity and incredible solidarity with the Ukrainian people. The Ukraine Crisis Relief Fund has been GlobalGiving’s largest fundraiser in its 20-year history. As a supporter of this fund, you are playing an important role in providing a lifeline to Ukrainians and addressing their long-term needs.',
    },
  ],
  64: [
    {
      image: 'ca/young.jpg',
      title: 'BGC Canada -Support Young People',
      goal: '$1,000,000',
      raised: '$61,157',
      donations: '1,287',
      link: 'https://www.globalgiving.org/projects/boys-and-girls-clubs-of-canada-supporting-young-people/',
      summary:
        'At BGC Canada, our mission is to provide safe, supportive places where children and youth can experience new opportunities, overcome barriers, build positive relationships, and develop confidence and skills for life.',
    },
    {
      image: 'ca/kelp-forests.jpg',
      title: 'Restoring Kelp Forests with Nanotechnology',
      goal: '$100,000',
      raised: '$1,100',
      donations: '25',
      link: 'https://www.globalgiving.org/projects/restoring-coastal-resilience-in-eastern-canada/',
      summary:
        'Around the world, massive kelp forests are rapidly declining due to coastal development and erosion, warming waters, invasive species, poor water quality, pollution, and overfishing. Nova Scotia has lost 85-99% of their kelp forests over the past 4-6 decades, putting their fisheries and livelihoods in jeopardy. Reef Life Foundation is developing a new method for restoring kelp using nanotechnology artificial reefs with the ocean science company IntelliReefs CAN and Dalhousie University.',
    },
    {
      image: 'all/afghanistan.jpg',
      title: 'Afghanistan Emergency Fund',
      goal: '$3,000,000',
      raised: '$1,512,64',
      donations: '5,816',
      link: 'https://www.globalgiving.org/projects/afghanistan-emergency-fund/',
      summary:
        "We believe that organizations that are deeply rooted in local communities are in the best position to provide long-term support for survivors of natural disasters like these. By funding the relief efforts of local organizations, donations to this fund have the potential to build stronger response capacity so that these organizations are better equipped to face future challenges.\
        The projects listed below are led by vetted GlobalGiving partners responding to this disaster. You can donate directly to a specific project or to GlobalGiving’s Afghanistan Emergency Fund and we'll allocate it to where it's needed most. GlobalGiving will post reports about how your donation has been put to use.",
    },
    {
      image: 'all/syria.jpg',
      title: 'Syrian Refugee Relief Fund',
      goal: '$2,500,000',
      raised: '$2,164,044',
      donations: '20,394',
      link: 'https://www.globalgiving.org/projects/syrian-refugee-relief-fund/',
      summary:
        'Ten years of armed conflict in Syria have caused millions of people to leave their homes in search of a safe haven. Fleeing for their lives, refugees are forced to leave all that they have behind. This fund will support relief efforts by organizations working in the area who are providing emergency assistance and ongoing support to those in need.',
    },
    {
      image: 'all/pregnancy.jpg',
      title: 'Supporting Women through Crisis Pregnancy',
      goal: '$350,000',
      raised: '$186,968',
      donations: '1,061',
      link: 'https://www.globalgiving.org/projects/safe-maternal-care-for-crisis-pregnancies-cambodia/',
      summary:
        '"Supporting women through crisis pregnancy." Save Cambodian mothers from having to sell their babies or placed in orphanages, remain in sex work, or feel apprehension at the arrival of their newborn. Give these women and their new babies a chance at life by providing them with quality health care, stable housing, nutritious food, vocational training, and job placements.',
    },
  ],
  121: [
    {
      image: 'us/island.jpg',
      title: 'The Island Spirit Fund',
      goal: '$10,000,000',
      raised: '$4,743,774',
      donations: '287',
      link: 'https://www.globalgiving.org/projects/island-spirit/',
      summary:
        'The Island Spirit Fund is dedicated to awakening the Island Spirit across the U.S., USVI, and where our partners live and work to ensure no one weathers a storm alone. It pursues this vision through relief efforts, helping rebuild and renew communities impacted by hurricanes. Grants are focused on awakening the Island Spirit on St. Croix in the aftermath of Hurricane Maria, and in other areas where our partners live and work, building resilience for future storms.',
    },
    {
      image: 'us/tornado.jpg',
      title: 'Midwest and Southern US Tornado Relief Fund',
      goal: '$5,000,000',
      raised: '$1,673,457',
      donations: '5,208',
      link: 'https://www.globalgiving.org/projects/midwest-tornado-relief-fund/',
      summary:
        'A deadly tornado outbreak ripped through six US states, including Arkansas, Illinois, Kentucky, Mississippi, Missouri, and Tennessee, leaving dozens dead and decimating entire towns. Your donation to this Tornado Relief Fund will help people in impacted communities.',
    },
    {
      image: 'us/crisis.jpg',
      title: 'Hope in Crisis Fund',
      goal: '$5,000,000',
      raised: '$654,721',
      donations: '37',
      link: 'https://www.globalgiving.org/projects/hope-in-crisis-fund/',
      summary:
        'Millions of people around the world are living through crises, but their stories are rarely heard in the mainstream news or raised to global awareness. The Hope in Crisis Fund will help communities struggling through intensifying natural and human-made disasters, including armed conflict, famine, persecution, and climate catastrophes.',
    },
    {
      image: 'us/girl.jpg',
      title: 'GlobalGiving Girl Fund',
      goal: '$500,000',
      raised: '$391,546',
      donations: '6,484',
      link: 'https://www.globalgiving.org/funds/girl-fund/',
      summary:
        'Can you imagine a world where every girl had the chance to create a future of her choosing? This fund supports highly effective GlobalGiving projects providing education, legal protection, health care, and access to training and job skills so that girls can thrive - making a difference in their lives and their communities.',
    },
    {
      image: 'all/ukraine.jpg',
      title: 'Ukraine Crisis Relief Fund',
      goal: '$57,000,000',
      raised: '$53,560,753',
      donations: '179,325',
      link: 'https://www.globalgiving.org/projects/ukraine-crisis-relief-fund/',
      summary:
        'It is hard to believe that the war in Ukraine has raged on for six months. GlobalGiving created the Ukraine Crisis Relief Fund days before Russia officially launched its invasion in February. At the time, our partners in Ukraine were already telling us that their communities were anxious and preparing for difficult days ahead.\
        As we continue to listen to our partners, they are sharing the worries they have for themselves and their families as the war drags on. They are worries like: “Funding has gone down—how can we keep supporting our people and our animals?” “The school year is starting, but where can we send our children safely and comfortably?” “The winter is coming, and we don’t know where we can stay.”\
        In six months, the war has resulted in several troubling records, including the largest and fastest displacement crisis in Europe since World War II, and the largest global fuel and energy crisis in decades.\
        But we have also seen record levels of generosity and incredible solidarity with the Ukrainian people. The Ukraine Crisis Relief Fund has been GlobalGiving’s largest fundraiser in its 20-year history. As a supporter of this fund, you are playing an important role in providing a lifeline to Ukrainians and addressing their long-term needs.',
    },
  ],
};
