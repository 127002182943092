import React, { useState } from 'react';
import clsx from 'clsx';
import { UTM_SOURCE_KEY, PARTNER_ID_KEY, AUTH_NETWORKS } from '../../../shared/configs/constants';
import { initDefaultCookieAgreement } from '../utils/agreement';
import { useResponseErrorMessage } from '@base/hooks/i18n';

import { injectedComponent, Text, useText } from '@base/core';

export default injectedComponent(
  {
    name: 'SignUpDialog',
    services: ['api', 'dialog', 'settings', 'notifier'],
    components: ['AuthDialogBase', 'LoginDialog', 'PostRegistrationDialog'],
    styles: ['dialog', 'button', 'self'],
  },
  function SignUpDialog({
    services: { api, dialog, notifier },
    components: { AuthDialogBase, LoginDialog, PostRegistrationDialog },
    styles: { dialog: dialogStyles, self: selfStyles },
    diSettings: { privacyNotice, cookiesPolicy, termsAndConditions },
  }) {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [updating, setUpdating] = useState(false);
    const [authError, setAuthError] = useState(null);
    const [validationMap, setValidationMap] = useState({
      auth: false,
      agreement: false,
    });
    const [validity, setValidity] = useState(false);
    const text = useText();
    const getErrorMessage = useResponseErrorMessage();

    const utm_source = sessionStorage.getItem(UTM_SOURCE_KEY) || '';
    const partnerId = sessionStorage.getItem(PARTNER_ID_KEY) || '';

    const handleLoginChange = (value) => {
      if (updating) return;
      setAuthError(null);
      setLogin(value);
    };

    const handlePasswordChange = (value) => {
      if (updating) return;
      setAuthError(null);
      setPassword(value);
    };

    const handleLoginDialog = () => {
      dialog.open(LoginDialog);
    };

    const processResponseError = (response) => {
      if (response && response.result === 'error') {
        const message = getErrorMessage(response);
        setAuthError(message);
      }
    };

    const PolicyLink = ({ link, textID }) => (
      <a
        href={link}
        target="_blank"
        className={clsx(dialogStyles.dialogLink, selfStyles.privacyPolicyLink)}
      >
        <Text>{'dialog.signup.' + textID}</Text>
      </a>
    );

    const handleSignUp = async () => {
      if (updating || !validity) return;
      setUpdating(true);
      try {
        const response = await api.register({
          payload: {
            contact: { identity: 'email', value: login },
            password,
            ...(utm_source ? { utm_source } : {}),
            ...(partnerId ? { partner: partnerId } : {}),
          },
          responseType: 'full',
        });
        if (response && response.result === 'success') {
          notifier.closeAll();
          initDefaultCookieAgreement();
          dialog.open(PostRegistrationDialog, btoa(login));
        } else {
          processResponseError(response);
        }
      } catch (e) {
        if (typeof e.json === 'function') {
          const response = await e.json();
          processResponseError(response);
        } else {
          setAuthError(text('dialog.signup.error_message__unknown_error'));
        }
      } finally {
        setUpdating(false);
      }
    };

    const handleSNLogin = (sn, baseUrl) => {
      if (!AUTH_NETWORKS.includes(sn)) return;
      const searchParams = new URLSearchParams();
      if (utm_source) {
        searchParams.append('utm_source', utm_source);
      }
      if (partnerId) {
        searchParams.append('partner', partnerId);
      }
      const params = searchParams.size > 0 ? `?${searchParams.toString()}` : '';
      document.location.assign(`${baseUrl}/auth/${sn}${params}`);
    };

    const validateChild = (validationId, childValidity) => {
      const newValidationMap = {
        ...validationMap,
        [validationId]: childValidity,
      };
      setValidationMap(newValidationMap);
      globalValidate(newValidationMap);
    };

    const globalValidate = (newValidationMap) => {
      const v = Object.values(newValidationMap).every((value) => value);
      setValidity(v);
    };

    const handleAgreementChange = (e) => {
      validateChild('agreement', e.target.checked);
    };

    return (
      <AuthDialogBase
        login={login}
        onLoginChange={handleLoginChange}
        password={password}
        onPasswordChange={handlePasswordChange}
        updating={updating}
        authError={authError}
        validationId="auth"
        validity={validity}
        validate={validateChild}
        title={<Text>dialog.signup.title</Text>}
        beforeActionButton={
          <div className={clsx(dialogStyles.regularText, selfStyles.agreementText)}>
            <span className={dialogStyles.formCheckbox}>
              <input
                type="checkbox"
                id="privacyCheckboxReg"
                className={dialogStyles.formCheckbox__input}
                checked={validationMap.agreement}
                onChange={handleAgreementChange}
              />
              <label
                className={dialogStyles.formCheckbox__checkbox}
                htmlFor="privacyCheckboxReg"
              ></label>
            </span>
            <span>
              I agree to
              <PolicyLink link={privacyNotice} textID="link__privacy_policy" />,
              <PolicyLink link={cookiesPolicy} textID="link__cookies_policy" /> and
              <PolicyLink link={termsAndConditions} textID="link__terms_and_conditions" />
            </span>
          </div>
        }
        actionButtonText={<Text>dialog.signup.button__create_account</Text>}
        onAction={handleSignUp}
        onSNLogin={handleSNLogin}
        afterActionButton={
          <>
            <div className={dialogStyles.regularText}>
              <Text>dialog.signup.text__have_an_account_q</Text>
              <span className={dialogStyles.dialogLink} onClick={handleLoginDialog}>
                <Text>dialog.signup.link__log_in</Text>
              </span>
            </div>
          </>
        }
      />
    );
  }
);
