import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Swiper, { Navigation, Pagination, Autoplay, Keyboard } from 'swiper';
import { injectedComponent } from '@base/core';

const swiperSettings = (charityProjects) => ({
  modules: [Navigation, Pagination, Autoplay, Keyboard],
  speed: 500,
  slidesPerView: 1,
  spaceBetween: 40,
  centeredSlides: true,
  pagination: {
    el: '.slider__pagination',
    bulletActiveClass: 'slider__bullet-active',

    clickable: true,
    slideToClickedSlide: true,
    bulletClass: 'slider__bullet',
    renderBullet: function (index, className) {
      return `<span class="${className}" style="background-image: url(&quot;/public/images/charity/${charityProjects[index].image}&quot;)">\
          <svg class="progressBarContainer">\
            <circle class="progressBar-circular" cx="30" cy="30" r="27"></circle>\
          </svg>\
        </span>`;
    },
  },
  keyboard: {
    enabled: true,
  },

  autoplay: {
    delay: 10000,
    disableOnInteraction: false,
  },
  loop: true,
});

function CharityContent({ swiperRef, charityProjects, onProjectOpen }) {
  return (
    <>
      <div ref={swiperRef} className="slider swiper">
        <div className="slider-wrapper swiper-wrapper">
          {charityProjects.map(
            ({ image, title, goal, raised, donations, link, summary }, index) => (
              <div
                key={image + index}
                className="swiper-slide slider-slide"
                style={{
                  backgroundImage: `linear-gradient(225deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)), url("/public/images/charity/${image}")`,
                }}
              >
                <div className="info slider__info">
                  <h3 className="caption slider__caption">
                    <span>
                      {raised} funded of {goal}
                    </span>
                    <span>{donations} donaters</span>
                  </h3>
                  <h2 className="title slider__title">{title}</h2>

                  <span
                    className="link slider__link js-modalLink"
                    data-slide={index}
                    onClick={onProjectOpen(index)}
                  >
                    About this funding
                  </span>
                </div>
              </div>
            )
          )}
        </div>
        <div className="slider__pagination"></div>
      </div>
    </>
  );
}

export default injectedComponent(
  {
    name: 'Charity',
    services: ['settings', 'dialog'],
    components: ['StyledSelect', 'CharityProjectDialog'],
  },
  function Charity({
    services: {
      dialog,
      settings: {
        countries,
        project: { charity },
      },
    },
    components: { StyledSelect, CharityProjectDialog },
  }) {
    const swiperRef = useRef();
    const swiperInstanceRef = useRef();
    const [countryId, setCountryId] = useState(121);
    const swiperRoot = document.querySelector('.swiper-container');
    const prerendered = document.querySelector('.prerendered-charity');

    if (prerendered) {
      prerendered.remove();
    }

    const charityProjects = charity[countryId];
    const currentCountry = countries.find(({ value }) => value === +countryId);

    const handleCountryChange = (value) => {
      setCountryId(+value);
    };

    const handleProjectOpen = (projectIndex) => () =>
      dialog.open(CharityProjectDialog, countryId + '-' + projectIndex);

    useEffect(() => {
      if (swiperInstanceRef.current) {
        swiperInstanceRef.current.destroy();
      }
      swiperInstanceRef.current = new Swiper(swiperRef.current, swiperSettings(charityProjects));
    }, [countryId]);

    return ReactDOM.createPortal(
      <>
        <div className="customSelect countrySelector">
          <StyledSelect
            options={countries}
            currentOption={currentCountry}
            onValueChange={handleCountryChange}
            imageBaseUrl="countries/"
            drawIcon={true}
            fullWidth={false}
            scrolled={false}
          />
        </div>
        <CharityContent
          swiperRef={swiperRef}
          charityProjects={charityProjects}
          onProjectOpen={handleProjectOpen}
        />
      </>,
      swiperRoot
    );
  }
);
