const i18n = {
  'faq.count': '13',
  'faq.title': 'Frequently Asked Questions',
  'faq.1.question': 'What is Responesta?',
  'faq.1.answer':
    '<p>Responesta is a platform where you can take online surveys and get rewarded for sharing your opinion. You can withdraw your money to a PayPal account or donate to one of the charities we work with.</p>',
  'faq.2.question': 'Why join Responesta?',
  'faq.2.answer':
    '<p>There are three major benefits:</p>\
  <ol>\
    <li>It is an easy way to earn money. Even on the go, you can take a short survey and make up the fare for your ride!</li>\
    <li>Help people in need in one click. You can donate your money to one of the charity funds we work with. We carefully select campaigns throughout the world. For that we cooperate with the major platform Global Giving.</li>\
    <li>Be among the first ones to know about new products and services. Market research is essential for developing new goods and services, as well as improving advertising campaigns. It is incredible – you can first see a new product in a survey, and soon be able to find it in a supermarket around the corner!</li>\
  </ol>',
  'faq.3.question': 'Is participation in Responesta free?',
  'faq.3.answer':
    '<p>Yes, it is totally free! Moreover, you will be able to get money for sharing your opinion.</p>',
  'faq.4.question': 'How would I get invited to surveys?',
  'faq.4.answer':
    '<p>You can get survey invitations via email, app, or messengers. Just choose the options that are more convenient for you.</p>',
  'faq.5.question': 'How much would I get for one survey?',
  'faq.5.answer':
    '<p>On average you will get 150 points. When you have 1000 points, you can withdraw them to your PayPal account or donate to charity.</p>',
  'faq.6.question': 'How often would I get invited to surveys?',
  'faq.6.answer':
    '<p>The amount of invitations can be very much different depending on your profile. Some people can get 5 surveys a day, others – 3 surveys a week. Our clients always need surveys to match the target audience, for example, dog owners living in big cities. We conduct hundreds of surveys every month, so for sure there will be some surveys for you!</p>',
  'faq.7.question': 'Why do you ask so many questions about myself?',
  'faq.7.answer':
    '<p>We need to know you better to be able to send you the surveys that are relevant or you. For example, for surveys about cars we would only invite the car owners, for surveys about baby food – only parents of babies etc.</p>',
  'faq.8.question': 'I joined Responesta. When do I start getting surveys?',
  'faq.8.answer':
    '<p>It depends on your profile. We invite people to surveys based on their profile information. For example, cat owners, parents of kids 1 -3 years old, gamers etc. We conduct several hundreds surveys every month, so soon there will be a one for you!</p>',
  'faq.9.question': 'I am having an issue with one of your surveys. What should I do?',
  'faq.9.answer':
    '<p>We are sorry to hear that! First you can try using some other browser. If it does not help, please contact our support desk at <b>support@responesta.com</b></p>',
  'faq.10.question': 'Which device (desktop, mobile, etc.) should I use for taking surveys?',
  'faq.10.answer':
    '<p>Our website is compatible with all devices, including computers, smart phones and tablets. Certain surveys can be accessed on desktop only – this will be specified in the survey description in your personal account.</p>',
  'faq.11.question':
    'I completed a survey, but the points have not been credited to my account. What should I do?',
  'faq.11.answer':
    '<p>Normally points are being credited automatically after you complete a survey. But it might happen that after taking a survey you have not been redirected to the page where you get your points. Then we will credit your points manually when we get all participants’ IDs from our client. It can take a couple of weeks, but don’t worry, we always keep track on that and mane sure all the participants get their points. You can also contact us at support@responesta.com if you want to get your points sooner.</p>',
  'faq.12.question': 'How do I redeem my points?',
  'faq.12.answer':
    '<p>You can redeem your points on your PayPal account, bank card, or donate to charity. Soon there will be new ways to redeem your points. You can always check Redeem section of your Personal account to see all available options.</p>',
  'faq.13.question': 'Can my points expire?',
  'faq.13.answer':
    '<p>When you keep taking surveys, your points cannot expire. However, if you do not take surveys within six months, we will send you an e-mail to confirm if you still want to participate. If you do not confirm your participation, your account will be frozen, and the points will be cancelled.</p>',

  'dialog.contact_us.title': 'Contact us',
  'dialog.contact_us.description': 'Ask any question about surveys or payment',
  'dialog.contact_us.placeholder__your_email': 'Your email',
  'dialog.contact_us.placeholder__your_question': 'Your question',
  'dialog.contact_us.button__send_a_message': 'Send a message',
  'dialog.contact_us.notification__success': "Thank you, we'll contact you as soon as possible",
  'dialog.contact_us.error_message__unknown_error': 'Something went wrong. Try contact us later',

  'dialog.thanks.title': 'Thanks!',
  'dialog.thanks.description': 'We will contact you as soon as possible',
  'dialog.thanks.button__ok': 'I’ll wait',
};

export default i18n;
