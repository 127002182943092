import React, { useState, useEffect, useRef } from 'react';
import { injectedComponent } from '@base/core';
import { useLocaleSwitchApp } from '@base/hooks/i18n';

function UnauthorizedUserWebMenu({ onLogin, onSignUp }) {
  return (
    <div className="header__loginBlock">
      <a href="#" className="link" onClick={onLogin}>
        Log in
      </a>
      <button className="primaryBtn primaryBtn-small js-registrationBtn" onClick={onSignUp}>
        Sign up
      </button>
    </div>
  );
}

function UnauthorizedUserMobileMenu({ onLogin, onSignUp }) {
  return (
    <div className="header__loginBlock-mobile ">
      <button
        className="primaryBtn btn-fullwidth js-registrationBtn loginBlock-btn"
        onClick={onSignUp}
      >
        Sign up
      </button>
      <button className="secondaryBtn btn-fullwidth loginBlock-btn" onClick={onLogin}>
        Log in
      </button>
    </div>
  );
}

export default injectedComponent(
  {
    name: 'HeaderBar',
    services: ['access', 'dialog', 'settings'],
    hooks: ['useDetectPredefinedNotifications'],
    components: ['LoginDialog', 'SignUpDialog', 'FAQDialog', 'WebHeaderUserInfo', 'Spinner'],
  },
  function HeaderBar({
    services: {
      access,
      dialog,
      settings: {
        animation: { userAccessDetecting },
      },
    },
    hooks: { useDetectPredefinedNotifications },
    components: { LoginDialog, SignUpDialog, FAQDialog, WebHeaderUserInfo, Spinner },
  }) {
    const navRef = useRef();
    const mobileMenuBtnRef = useRef();
    const detectPredefinedNotifications = useDetectPredefinedNotifications();
    const switchApp = useLocaleSwitchApp();

    const [isUserMenuOpened, setUserMenuState] = useState(false);

    const handleLoginDialog = () => {
      dialog.open(LoginDialog);
    };

    const handleSignUpDialog = () => {
      dialog.open(SignUpDialog);
    };

    const handleFAQDialog = () => {
      dialog.open(FAQDialog);
    };

    const handleDialogOpen = (dialogId) => () => {
      switchApp('my/', { dialog: dialogId });
    };

    const handleLogout = () => {
      setUserMenuState(false);
      access.logout();
    };

    const handleMobileMenuToggle = () => {
      const navigation = navRef.current;
      const mobileMenuBtn = mobileMenuBtnRef.current;
      if (mobileMenuBtn.classList.contains('menu__mobile-opened')) {
        mobileMenuBtn.classList.remove('menu__mobile-opened');
        navigation.classList.add('header__navlist-closed');
        navigation.classList.remove('header__navlist-opened');
        return false;
      }
      mobileMenuBtn.classList.add('menu__mobile-opened');
      navigation.classList.toggle('header__navlist-closed', false);
      navigation.classList.add('header__navlist-opened');
    };

    useEffect(() => {
      detectPredefinedNotifications();
    }, []);

    return (
      <header className="headerContainer">
        <div className="header">
          <nav className="header__navigation">
            <div className="header__navigation__logo">
              <img src="/public/images/Responesta-logo.png" alt="Logo" />
            </div>
            <span className="link" onClick={handleFAQDialog}>
              FAQ
            </span>
            <a href="#charityBlock" className="link">
              Charity
            </a>
          </nav>
          {!access.detected ? (
            <div className="HeaderBar__SpinnerContainer">
              <Spinner animationData={userAccessDetecting} />
            </div>
          ) : access.hasAnyAccess() ? (
            <WebHeaderUserInfo
              hasMenu={true}
              isUserMenuOpened={isUserMenuOpened}
              setUserMenuState={setUserMenuState}
              openDialog={handleDialogOpen}
              logout={handleLogout}
            />
          ) : (
            <UnauthorizedUserWebMenu onLogin={handleLoginDialog} onSignUp={handleSignUpDialog} />
          )}
        </div>
        <nav className="header__navigation-mobile">
          <div className="header__logo">
            <img src="/public/images/Responesta-logo.png" alt="Logo" />
          </div>
          <div className="header__buttonBlock-mobile">
            {access.hasAnyAccess() ? (
              <WebHeaderUserInfo
                isUserMenuOpened={isUserMenuOpened}
                setUserMenuState={setUserMenuState}
                openDialog={handleDialogOpen}
                logout={handleLogout}
              />
            ) : (
              <button
                className="primaryBtn primaryBtn-small js-registrationBtn"
                onClick={handleSignUpDialog}
              >
                Sign up
              </button>
            )}
            <button
              ref={mobileMenuBtnRef}
              className="js-menu__mobile menu__mobile"
              onClick={handleMobileMenuToggle}
            >
              <div className="menu__icon"></div>
            </button>
          </div>
        </nav>
        <div ref={navRef} className="js-navigation header__navlist-closed">
          <div className="header__links-mobile">
            <span onClick={handleFAQDialog} className="header__link-mobile js-faqLink">
              FAQ
            </span>
            <a href="#charityBlock" className="header__link-mobile">
              Charity
            </a>
            {access.hasAnyAccess() && (
              <>
                <a href="/my/" className="header__link-mobile">
                  My Surveys
                </a>
                <a href="/my/?dialog=profile" className="header__link-mobile">
                  My Profile
                </a>
                <a href="/my/?dialog=transactions" className="header__link-mobile">
                  Transactions
                </a>
                <a href="/my/?dialog=profile" className="header__link-mobile">
                  Settings
                </a>
                <a className="header__link-mobile" onClick={handleLogout}>
                  Log out
                </a>
              </>
            )}
          </div>
          {!access.hasAnyAccess() && (
            <UnauthorizedUserMobileMenu onLogin={handleLoginDialog} onSignUp={handleSignUpDialog} />
          )}
        </div>
      </header>
    );
  }
);
