import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import 'dayjs/locale/en-ca';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/en-au';
import 'dayjs/locale/en-nz';
import localeData from 'dayjs/plugin/localeData';
import objectSupport from 'dayjs/plugin/objectSupport';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import bootstrap from '@base/core/bootstrap';
import { inIFrame } from '@base/utils/utils';

import endpoints from './configs/endpoints';
import routes from './configs/routes';
import i18n from './configs/i18n';
import countries from './configs/countries';
import dialogSettings from './configs/dialogs';
import documents from './configs/documents';
import { COOKIES_AGREEMENT_KEY } from './configs/constants';
import { API_TOKEN_HEADER_KEY } from '../../shared/configs/constants';
import charity from '../../shared/configs/charity';

import { ServiceRegistry } from '@base/core/ServiceRegistry';
import LanguageServiceProvider from '@base/services/LanguageService';
import SettingsServiceProvider from '@base/services/SettingsService';
import ApiServiceProvider from '@base/services/ApiService';
import AccessServiceProvider from '@base/services/AccessService';
import DialogServiceProvider from '@base/services/DialogService';
import AssetServiceProvider from '@base/services/AssetService';
import NotifierServiceProvider from '@base/services/NotifierService';
import UserServiceProvider from '@base/services/UserService';
import I18nRouter from '@base/routers/I18nRouter';

import '../../shared/styles/theme.css';
import './styles/extracted/customSelect.css';
import './styles/extracted/swiper.min.css';
import './styles/extracted/styles.css';

import animationFrame1 from '../../shared/animation/Frame1.json';

import { registerDependencyInjections } from '@base/core';
import styles from './di/styles';
import services from './di/services';
import hooks from './di/hooks';
import components from './di/components';

registerDependencyInjections({ services, hooks, components, styles });

const appRootElement = document.getElementById('app-root');
const dialogRootElement = document.getElementById('dialog-root');
const popupRootElement = document.getElementById('popup-root');

import HeaderBar from './components/HeaderBar';
import Legacy from './components/Legacy';
import Charity from './components/Charity';
import CookieAgreement from '@base/components/public/CookieAgreement';
import CookieAgreementContent, {
  handleCookiesAgreement,
} from './components/CookieAgreementContent';
import ErrorParamDetector from '@base/components/ErrorParamDetector';
import MVFPostback from './components/MVFPostback';

dayjs.extend(objectSupport);
dayjs.extend(localeData);
dayjs.extend(localizedFormat);

const appSettings = {
  routes,
  i18n,
  countries,
  animation: {
    userAccessDetecting: animationFrame1,
  },
  keys: {
    cookiesAgreementKey: COOKIES_AGREEMENT_KEY,
  },
  project: {
    charity,
  },
};

import InactiveAccountDialog from './dialogs/InactiveAccountDialog';

const errorCases = {
  inactive: ({ dialog }) => {
    dialog.open(InactiveAccountDialog);
  },
  multipleMembers: {
    type: 'notification',
    textKey: 'global_error.multipleMembers',
    notifierOptions: { topPosition: true },
  },
};

const defaultErrorParamCase = {
  type: 'notification',
  textKey: 'global_error.unknown_error',
  notifierOptions: { topPosition: true },
};

import i18n_en from './i18n/en';
import i18n_en_error_map from './i18n/en__error-map';
import shared_i18n_en from '../../shared/i18n/en';

window.i18n = {
  en: { ...i18n_en, ...i18n_en_error_map, ...shared_i18n_en },
};

async function run() {
  if (inIFrame()) {
    window.parent.document.location.assign(document.location);
  } else {
    try {
      const apiConfig = await bootstrap();
      const appRoot = ReactDOM.createRoot(appRootElement);
      appRoot.render(
        <ServiceRegistry>
          <SettingsServiceProvider id="settings" settings={appSettings}>
            <LanguageServiceProvider id="language">
              <ApiServiceProvider
                id="api"
                apiConfig={apiConfig}
                endpoints={endpoints}
                tokenHeader={API_TOKEN_HEADER_KEY}
              >
                <AccessServiceProvider id="access">
                  <AssetServiceProvider id="assets" base="web">
                    <BrowserRouter>
                      <I18nRouter>
                        <UserServiceProvider id="user" needBalance={false}>
                          <NotifierServiceProvider id="notifier" rootElement={popupRootElement}>
                            <DialogServiceProvider
                              id="dialog"
                              dialogRootElement={dialogRootElement}
                              dialogSettings={dialogSettings}
                            >
                              <CookieAgreement
                                notificaionContent={(id) => (
                                  <CookieAgreementContent
                                    id={id}
                                    cookiesPolicyUrl={documents.cookiesPolicy}
                                  />
                                )}
                                onAgreement={handleCookiesAgreement}
                              />
                              <MVFPostback />
                              <HeaderBar />
                              <Legacy />
                              <Charity />
                              <ErrorParamDetector
                                errorCases={errorCases}
                                defaultCase={defaultErrorParamCase}
                              />
                            </DialogServiceProvider>
                          </NotifierServiceProvider>
                        </UserServiceProvider>
                      </I18nRouter>
                    </BrowserRouter>
                  </AssetServiceProvider>
                </AccessServiceProvider>
              </ApiServiceProvider>
            </LanguageServiceProvider>
          </SettingsServiceProvider>
        </ServiceRegistry>
      );
    } catch (error) {
      console.log(error);
    }
  }
}

run();
