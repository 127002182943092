// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loDxkXotNJlQ7MVbyuzg {\n  font-size: 12px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n}\n\n.Yngit0x5qpOOs48RX5Hu {\n  font-size: 12px;\n  text-decoration: none;\n}\n", "",{"version":3,"sources":["webpack://./packages/robinhood/public/src/styles/components/SignUpDialog.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,qBAAqB;AACvB","sourcesContent":[".agreementText {\n  font-size: 12px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n}\n\n.privacyPolicyLink {\n  font-size: 12px;\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"agreementText": "loDxkXotNJlQ7MVbyuzg",
	"privacyPolicyLink": "Yngit0x5qpOOs48RX5Hu"
};
export default ___CSS_LOADER_EXPORT___;
