import React, { useState, useRef } from 'react';
import clsx from 'clsx';

import { injectedComponent, Text, useText } from '@base/core';
import { useResponseErrorMessage } from '@base/hooks/i18n';
import { EMAIL_REGEXP } from '../configs/constants';

export default injectedComponent(
  {
    name: 'ContactUsDialog',
    services: ['api', 'dialog', 'notifier', 'user', 'access'],
    components: ['PostContactUsDialog'],
    styles: ['dialog', 'targetDialog', 'button'],
  },
  function ContactUsDialog({
    services: { api, dialog, notifier, user, access },
    components: { PostContactUsDialog },
    styles: { dialog: dialogStyles, targetDialog: targetDialogStyles, button: buttonStyles },
  }) {
    const [email, setEmail] = useState();
    const [message, setMessage] = useState();
    const [validity, setValidity] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [error, setError] = useState(null);
    const text = useText();
    const getErrorMessage = useResponseErrorMessage();

    const emailRef = useRef();
    const messageRef = useRef();

    const validate = () => {
      setValidity(emailRef.current.checkValidity() && messageRef.current.checkValidity());
    };

    const handleEmailChange = (e) => {
      if (updating) return;
      validate();
      setError(null);
      setEmail(e.target.value);
    };

    const handleMessageChange = (e) => {
      if (updating) return;
      validate();
      setError(null);
      setMessage(e.target.value);
    };

    const processResponseError = (response) => {
      if (response && response.result === 'error') {
        const message = getErrorMessage(response);
        setError(message);
      }
    };

    const handleRecovery = async () => {
      if (updating || !validity || !email || !message) return;
      setUpdating(true);
      try {
        const response = await api.sendMessage({
          payload: {
            ...(access.isLogged && user.id ? { memberId: user.id } : {}),
            contactEmail: email,
            message,
          },
          responseType: 'full',
        });
        if (response && response.result === 'success') {
          dialog.open(PostContactUsDialog);
        } else {
          processResponseError(response);
          // if (response && response.message) {
          //   setError(response.message);
          // } else {
          //   setError(text('dialog.contact_us.error_message__unknown_error'));
          // }
        }
      } catch (e) {
        if (typeof e.json === 'function') {
          const response = await e.json();
          processResponseError(response);
        } else {
          setError(text('dialog.contact_us.error_message__unknown_error'));
        }
        // if (e.status === 500) {
        //   const response = await e.json();
        //   if (response && response.message) {
        //     setError(response.message);
        //   }
        // } else {
        //   setError(text('dialog.contact_us.error_message__unknown_error'));
        // }
      } finally {
        setUpdating(false);
      }
    };

    return (
      <div className={clsx(targetDialogStyles.dialog)}>
        <h3 className={dialogStyles.dialogTitle}>
          <Text>dialog.contact_us.title</Text>
        </h3>
        <p className={dialogStyles.description}>
          <Text>dialog.contact_us.description</Text>
        </p>
        <form className={dialogStyles.form}>
          <input
            ref={emailRef}
            type="email"
            className={dialogStyles.formInput}
            placeholder={text('dialog.contact_us.placeholder__your_email')}
            pattern={EMAIL_REGEXP}
            value={email || ''}
            onChange={handleEmailChange}
          />
          <textarea
            ref={messageRef}
            className={clsx(dialogStyles.formInput, dialogStyles.formInput__textArea)}
            placeholder={text('dialog.contact_us.placeholder__your_question')}
            pattern="\w+"
            value={message}
            onChange={handleMessageChange}
          ></textarea>
          {error && <span className={clsx(dialogStyles.errorMessage)}>{error}</span>}
          <button
            type="button"
            className={clsx(buttonStyles.primaryBtn, buttonStyles.primaryBtn__medium, {
              [buttonStyles.primaryBtn__disabled]: !validity || !email || !message,
            })}
            onClick={handleRecovery}
          >
            <Text>dialog.contact_us.button__send_a_message</Text>
          </button>
        </form>
      </div>
    );
  }
);
