import React from 'react';
import { injectedComponent } from '@base/core';
import { ONLY_TECH_COOKIES, ALL_COOKIES } from '../configs/constants';
import initGoogle from '../../../shared/components/google';
import initMetaPixel from './cookies-agreement-content/meta';
import initPixel from './cookies-agreement-content/pixel';

const PRODUCTION = 'production';

export function handleCookiesAgreement(cookiesAgreement) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const canInitCookiesAgreement =
    process.env.NODE_ENV === PRODUCTION || params.platform === PRODUCTION;
  if (cookiesAgreement === ALL_COOKIES && canInitCookiesAgreement) {
    initGoogle();
    // initMetaPixel();
    initPixel();
  }
}

export default injectedComponent(
  {
    name: 'CookieAgreementContent',
    services: ['notifier', 'settings'],
    styles: ['self'],
  },
  function CookieAgreementContent({
    id,
    cookiesPolicyUrl,
    services: {
      notifier,
      settings: {
        keys: { cookiesAgreementKey },
      },
    },
    styles: { self: selfStyles },
  }) {
    const handleOnlyTechCookiesAgreement = () => {
      localStorage.setItem(cookiesAgreementKey, ONLY_TECH_COOKIES);
      notifier.close(id);
    };

    const handleAllCookiesAgreement = () => {
      localStorage.setItem(cookiesAgreementKey, ALL_COOKIES);
      notifier.close(id);
      handleCookiesAgreement(ALL_COOKIES);
    };

    return (
      <div className={selfStyles.root}>
        <div className={selfStyles.text}>
          We use cookies. By clicking "Accept All", you consent to our use of cookies.
        </div>
        <div className={selfStyles.buttonContainer}>
          <a className={selfStyles.button} onClick={handleOnlyTechCookiesAgreement}>
            Only technically necessary cookies
          </a>
          <a href={cookiesPolicyUrl} target="_blank" className={selfStyles.button}>
            More information
          </a>
          <a className={selfStyles.button} onClick={handleAllCookiesAgreement}>
            Accept all
          </a>
        </div>
      </div>
    );
  }
);
