import React, { useState, useRef } from 'react';
import clsx from 'clsx';

import { injectedComponent, Text, useText } from '@base/core';
import { useResponseErrorMessage } from '@base/hooks/i18n';

export default injectedComponent(
  {
    name: 'PasswordRecoveryDialog',
    services: ['api', 'dialog', 'notifier'],
    components: ['LoginDialog'],
    styles: ['dialog', 'targetDialog', 'button'],
  },
  function PasswordRecoveryDialog({
    services: { api, dialog, notifier },
    components: { LoginDialog },
    styles: { dialog: dialogStyles, targetDialog: targetDialogStyles, button: buttonStyles },
  }) {
    const [email, setEmail] = useState(dialog.param && atob(dialog.param));
    const [validity, setValidity] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [error, setError] = useState(null);
    const text = useText();
    const getErrorMessage = useResponseErrorMessage();

    const emailRef = useRef();

    const validate = () => {
      setValidity(emailRef.current.checkValidity());
    };

    const handleEmailChange = (e) => {
      if (updating) return;
      validate();
      setError(null);
      setEmail(e.target.value);
    };

    const handleBackToLoginDialog = () => {
      dialog.open(LoginDialog);
    };

    const processResponseError = (response) => {
      if (response && response.result === 'error') {
        const message = getErrorMessage(response);
        setError(message);
      }
    };

    const handleRecovery = async () => {
      if (updating || !validity) return;
      setUpdating(true);
      try {
        const response = await api.resorePassword({
          payload: { email },
          responseType: 'full',
        });
        if (response && response.result === 'success') {
          notifier.push('got it!');
          dialog.close();
        } else {
          processResponseError(response);
          // if (response && response.message) {
          //   setError(response.message);
          // } else {
          //   setError(text('dialog.password_recovery.error_message__unknown_error'));
          // }
        }
      } catch (e) {
        if (typeof e.json === 'function') {
          const response = await e.json();
          processResponseError(response);
        } else {
          setError(text('dialog.password_recovery.error_message__unknown_error'));
        }
        // if (e.status === 500) {
        //   const response = await e.json();
        //   if (response && response.message) {
        //     setError(response.message);
        //   }
        // } else {
        //   setError(text('dialog.password_recovery.error_message__unknown_error'));
        // }
      } finally {
        setUpdating(false);
      }
    };

    return (
      <div className={clsx(targetDialogStyles.dialog)}>
        <h3 className={dialogStyles.dialogTitle}>
          <Text>dialog.password_recovery.title</Text>
        </h3>
        <p className={dialogStyles.description}>
          <Text>dialog.password_recovery.description</Text>
        </p>
        <form className={dialogStyles.form}>
          <input
            ref={emailRef}
            type="email"
            className={dialogStyles.formInput}
            placeholder={text('dialog.password_recovery.placeholder__your_email')}
            pattern="[\w\-\.]+@\w+\.\w+\.?\w*"
            value={email || ''}
            onChange={handleEmailChange}
          />
          {error && <span className={clsx(dialogStyles.errorMessage)}>{error}</span>}
          <button
            type="button"
            className={clsx(buttonStyles.primaryBtn, buttonStyles.primaryBtn__medium, {
              [buttonStyles.primaryBtn__disabled]: !validity || !email,
            })}
            onClick={handleRecovery}
          >
            <Text>dialog.password_recovery.button__send_a_link</Text>
          </button>
          <p className={dialogStyles.regularText}>
            <Text>dialog.password_recovery.text__remembered_q</Text>
            <span className={dialogStyles.dialogLink} onClick={handleBackToLoginDialog}>
              <Text>dialog.password_recovery.link__back_to_login</Text>
            </span>
          </p>
        </form>
      </div>
    );
  }
);
