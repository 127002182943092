import React, { useEffect } from 'react';
import { injectedComponent } from '@base/core';
import { UTM_SOURCE_KEY, PARTNER_ID_KEY } from '../../../shared/configs/constants';
import dayjs from 'dayjs';

const initTotalPaidValue = '9612130'; // Init number for counter

export default injectedComponent(
  {
    name: 'Legacy',
    services: ['dialog'],
    components: ['SignUpDialog', 'ContactUsDialog', 'FAQDialog'],
  },
  function Legacy({
    services: { dialog },
    components: { SignUpDialog, ContactUsDialog, FAQDialog },
  }) {
    const handleLoginDialog = () => {
      dialog.open(SignUpDialog);
    };

    const handleContactUsDialog = () => {
      dialog.open(ContactUsDialog);
    };

    useEffect(() => {
      const loginButtons = document.getElementsByClassName('js-registrationBtn');
      for (const loginBtn of loginButtons) {
        loginBtn.addEventListener('click', handleLoginDialog);
      }

      const contactUsBtn = document.querySelector('.js-footerContactBtn');
      contactUsBtn.addEventListener('click', handleContactUsDialog);

      for (let i = 1; i < initTotalPaidValue.length + 1; i++) {
        const target = document.querySelector(`.js-digits-${i}`);
        target.style.marginTop = `-${initTotalPaidValue[i - 1]}em`; // Init counter animation
      }

      const copyrightYears = document.getElementsByClassName('copyright-year');
      for (const copyrightYear of copyrightYears) {
        copyrightYear.textContent = dayjs().year();
      }
    }, []);

    const searchParams = new URLSearchParams(window.location.search);
    const utm_source = searchParams.get(UTM_SOURCE_KEY);
    const partnerID = searchParams.get(PARTNER_ID_KEY);
    if (utm_source) {
      sessionStorage.setItem(UTM_SOURCE_KEY, utm_source);
    }
    if (partnerID) {
      sessionStorage.setItem(PARTNER_ID_KEY, partnerID);
    }
    return null;
  }
);
