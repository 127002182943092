const i18n = {
  'navigation.my_surveys': 'My Surveys',
  'navigation.my_profile': 'My Profile',
  'navigation.transactions': 'Transactions',
  'navigation.settings': 'Settings',
  'navigation.logout': 'Log out',

  'dialog.login.title': 'Log in to your account',
  'dialog.login.button__google': 'Google',
  'dialog.login.button__apple': 'Apple',
  'dialog.login.button__facebook': 'Facebook',
  'dialog.login.divider__or_via_email': 'or via email',
  'dialog.login.link__forgot_password_q': 'Forgot password?',
  'dialog.login.text__dont_have_an_account_q': "Don't have an account?",
  'dialog.login.link__create_account': 'Create account',
  'dialog.login.button__login': 'Log in',
  'dialog.login.error_message__invalid_login_password':
    'That email and password combination is incorrect',
  'dialog.login.error_message__unknown_error': 'Something went wrong. Try login later',
  'dialog.login.error_message__inactive_member': 'Inactive account',

  'dialog.signup.title': 'Create account',
  'dialog.signup.button__create_account': 'Create account',
  'dialog.signup.text__by_clicking_I_agree':
    'By clicking "Create account", I agree to %policy1%, %policy2% and %policy3%',
  'dialog.signup.link__privacy_policy': 'Privacy Policy',
  'dialog.signup.link__cookies_policy': 'Cookies Policy',
  'dialog.signup.link__terms_and_conditions': 'Terms and Conditions',
  'dialog.signup.text__have_an_account_q': 'Have an account?',
  'dialog.signup.link__log_in': 'Log in',
  'dialog.signup.error_message__member_already_exist': 'Member with this email already exists',
  'dialog.signup.error_message__unknown_error': 'Something went wrong. Try sign up later',

  'dialog.post_signup.title': 'Verify your email',
  'dialog.post_signup.text__description':
    'To use Responesta, click the verification button in the email we sent to ',
  'dialog.post_signup.text__footer': 'This helps keep your account secure',

  'dialog.password_recovery.title': 'Password recovery',
  'dialog.password_recovery.description':
    'Enter your email for us to send a link for a password change',
  'dialog.password_recovery.placeholder__your_email': 'Your email',
  'dialog.password_recovery.button__send_a_link': 'Send a link',
  'dialog.password_recovery.text__remembered_q': 'Remembered?',
  'dialog.password_recovery.link__back_to_login': 'Back to login',
  'dialog.password_recovery.error_message__unknown_error':
    'Something went wrong. Try recovery later',

  'dialog.inactive_account.text__message': 'Inactive account',

  'dialog.stop_reminder.description':
    'You have successfully unsubscribed from invitations to this survey',

  'dialog.unsubscribe.description':
    'Please confirm that you would like to unsubscribe from Responesta and delete your account. Please pay attention that all your points will be cancelled.',
  'dialog.unsubscribe.button__delete': 'Yes, delete my account please',

  'common.notification__we_use_cookies':
    'We use cookies. By clicking "Ok" button you accept our %value% <a>Cookies Policy</a>. Please use "Opt-out option" column of Cookies Policy to customize settings',
};

export default i18n;
