import documents from '../configs/documents';
import { initDefaultCookieAgreement } from '../utils/agreement';

export default {
  Charity: {
    self: require('../components/Charity').default,
  },
  CookieAgreement: {
    self: require('@base/components/public/CookieAgreement').default,
    settings: {
      notification: {
        hideButton: true,
      },
    },
  },
  CookieAgreementContent: {
    self: require('../components/CookieAgreementContent').default,
    styles: {
      self: require('../styles/components/CookieAgreementContent.module.css').default,
    },
  },
  CountrySelector: {
    self: require('../components/CountrySelector').default,
    styles: {
      self: require('../styles/components/CountrySelector.module.css').default,
    },
  },
  DefaultAvatar: require('@base/components/avatar/DefaultAvatar').default,
  DialogPortal: require('@base/components/services/DialogService/DialogPortal').default,
  ErrorParamDetector: require('@base/components/ErrorParamDetector').default,
  Legacy: require('../components/Legacy').default,
  ModalDialog: {
    self: require('@base/components/services/DialogService/ModalDialog').default,
    styles: {
      self: require('../styles/components/ModalDialog.module.css').default,
    },
  },
  MVFPostback: require('../components/MVFPostback').default,
  PopUpContainer: require('@base/components/services/NotifierService/PopUpContainer').default,
  PopUpNotification: require('@base/components/services/NotifierService/PopUpNotification').default,
  Spinner: require('@base/components/Spinner').default,
  StyledSelect: {
    self: require('@base/components/StyledSelect').default,
    styles: {
      self: require('../../../shared/styles/components/StyledSelect.module.css').default,
    },
  },
  UserAvatar: require('@base/components/avatar/UserAvatar').default,
  HeaderBar: require('../components/HeaderBar').default,
  WebHeaderUserInfo: {
    self: require('../components/WebHeaderUserInfo').default,
    styles: {
      self: require('../../../shared/styles/components/WebHeaderUserInfo.module.css').default,
    },
  },
  // DIALOGS ------------------------------------------------------------------------
  AuthDialogBase: {
    self: require('../dialogs/components/AuthDialogBase').default,
    styles: {
      self: require('../../../shared/styles/components/AuthDialogBase.module.css').default,
    },
    settings: {
      navigate: 'my/',
    },
  },
  CharityProjectDialog: {
    tags: 'dialog',
    self: require('../dialogs/CharityProjectDialog').default,
    styles: {
      self: require('../styles/components/CharityProjectDialog.module.css').default,
    },
  },
  ContactUsDialog: {
    tags: 'dialog',
    self: require('../../../shared/dialogs/ContactUsDialog').default,
  },
  InactiveAccountDialog: {
    tags: 'dialog',
    self: require('../dialogs/InactiveAccountDialog').default,
  },
  FAQDialog: {
    tags: 'dialog',
    self: require('@base/dialogs/FAQDialog').default,
    styles: {
      self: require('../../../shared/styles/components/FAQDialog.module.css').default,
    },
  },
  LoginDialog: {
    tags: 'dialog',
    self: require('../dialogs/LoginDialog').default,
    styles: {
      self: require('../../../shared/styles/components/AuthDialogBase.module.css').default,
    },
    settings: {
      switchAppOnLogin: 'my/',
      onLogin: () => {
        initDefaultCookieAgreement();
      },
    },
  },
  PasswordRecoveryDialog: {
    tags: 'dialog',
    self: require('../dialogs/PasswordRecoveryDialog').default,
  },
  PostContactUsDialog: {
    tags: 'dialog',
    self: require('../../../shared/dialogs/PostContactUsDialog').default,
    styles: {
      self: require('../../../shared/styles/components/PostContactUsDialog.module.css').default,
    },
  },
  PostRegistrationDialog: {
    tags: 'dialog',
    self: require('../dialogs/PostRegistrationDialog').default,
  },
  SignUpDialog: {
    tags: 'dialog',
    self: require('../dialogs/SignUpDialog').default,
    styles: {
      self: require('../styles/components/SignUpDialog.module.css').default,
    },
    settings: {
      privacyNotice: documents.privacyNotice,
      cookiesPolicy: documents.cookiesPolicy,
      termsAndConditions: documents.termsAndConditions,
    },
  },
  StopReminderDialog: {
    tags: 'dialog',
    self: require('../dialogs/StopReminderDialog').default,
  },
  UnsubscribeDialog: {
    tags: 'dialog',
    self: require('../dialogs/UnsubscribeDialog').default,
  },
};
