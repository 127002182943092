module.exports = {
  API_TOKEN_HEADER_KEY: 'x-community-token',
  MVF_TRANSACTION_SESSION_KEY: 'tdl_transid',
  MVF_TRANSACTION_PARAM: 'transaction_id',
  MVF_NETWORK_SRC_VALUE: 'mvftunenetwork',
  MVF_POSTBACK_URL:
    'https://mvfglobal.go2cloud.org/aff_lsr?offer_id=265&transaction_id={{tdl_transid}}',
  UTM_SOURCE_KEY: 'utm_source',
  UTM_SOURCE_VALUE: 'rh_013',
  PARTNER_ID_KEY: 'partner',
  EMAIL_REGEXP: '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z0-9]{2,6}$',
  GTM_TAG_ID: 'GTM-TVGMGRXR',
  AUTH_NETWORKS: ['google', 'apple', 'facebook'],
};
